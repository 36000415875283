.bg-blue {
  background-color: aqua;
  border: 10px solid red;
}

.intercom-lightweight-app-launcher {
  bottom: 93px !important;
  left: calc(100vw - 100px) !important;
}

.intercom-lightweight-app {
  opacity: 1;
}
.intercom-namespace .intercom-dfosxs {
  bottom: 93px !important;
  left: calc(100vw - 100px) !important;
}

// .halfRoundDd {
//   // background-color: #fff;
//   // -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
//   // transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
//   -webkit-transition: none;s
//   transition: none;
//   border-radius: 4px;
//   box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
//     0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
//   max-height: calc(100% - 96px);
//   -webkit-overflow-scrolling: touch;
//   // background-color: #fff;

//   border-radius: 4px;
//   /* box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%); */
//   position: absolute;
//   overflow-y: auto;
//   overflow-x: hidden;
//   min-width: 16px;
//   min-height: 16px;
//   max-width: calc(100% - 32px);
//   max-height: calc(100% - 32px);
//   outline: 0;
//   background: #f3f3f3;
//   border: 1px solid black;
//   border-top: 0;
//   border-radius: 0;
//   margin-top: -2px;
//   border-bottom-right-radius: 40px;
// }

.videoPlayer {
  border: 1px solid rgba(255, 255, 255, 0.2);
  height: 100% !important;
  width: 380px !important;
  border-radius: 12px;
}

////HEADER BLOBS ANIMATIONS

@keyframes slow-wiggle {
  from {
    offset-distance: 0%;
  }
  to {
    offset-distance: 100%;
  }
}

.leftBlob {
  position: absolute;
  top: 0px;
  left: -50px;
  offset-path: path(
    'M467.097 209.493C389.472 163.548 288.483 176.618 253.231 274.054C217.978 371.489 299.175 411.806 435.413 378.617C558.98 348.515 557.002 575.123 666.309 497.836C762.267 429.988 686.506 324.355 626.307 324.355C537.198 324.355 514.227 237.389 467.097 209.493Z'
  );
  offset-distance: 0%;

  animation: slow-wiggle 60s linear infinite;
}

.rightBlob {
  position: absolute;
  top: 300px;
  right: 0px;
  offset-path: path(
    'M440.254 126.507C407.508 58.0069 330.913 22.7485 275.253 72.006C203.5 135.505 292.753 305.006 234.754 366.007C159.254 445.416 -5.74918 366.007 60.253 261.507C99.206 199.833 241.56 197.892 307.254 244.007C397 307.006 473 195.007 440.254 126.507Z'
  );
  offset-distance: 0%;

  animation: slow-wiggle 60s linear reverse infinite;
}

// .container {
//   position: relative;
//   width: 200px;
//   height: 200px;
// }
// .content {
//   width: 50%;
//   height: 50%;
//   left: 50px;
//   top: 50px;
//   position: absolute;
//   background-color: white;
//   z-index: 1;
//   border-radius: 50%;
// }
// .around {
//   background-color: red;
//   width: 100px;
//   height: 100px;
//   display: inline-block;
//   position: absolute;
// }
// .top-left {
//   border-top-left-radius: 100%;
//   top: 0;
//   left: 0;
//   background: blue;
// }
// .top-right {
//   border-top-right-radius: 100%;
//   top: 0;
//   right: 0;
//   background: green;
// }
// .bottom-left {
//   border-bottom-left-radius: 100%;
//   bottom: 0;
//   left: 0;
//   background: orange;
// }
// .bottom-right {
//   border-bottom-right-radius: 100%;
//   bottom: 0;
//   right: 0;
//   background: purple;
// }

// .left-top {
//   border-top-left-radius: 100%;
//   top: 0;
//   left: 0;
//   background: blue;
// }

// .btn {
//   position: relative;
//   display: inline-block;
//   padding: 10px 20px;
//   font-size: 16px;
//   background-color: black;
//   border-radius: 30.5px;
//   color: white;
//   border: none;
//   margin-top: 40px;
//   cursor: pointer;
//   img{
//   margin-top: 4px;

//   }
//   p{
//     padding: 0;
//     margin: 0;
//   }
// }

// .btn:hover {

//  p{
//   transition: .5s all;
//   padding: 0;
//   margin: 0;
//   margin-left: .9rem;

//  }
// }

// .btn:hover span {
//   opacity: 1;

// }

// .btn span {
//   position: absolute;
//   left: 10px;
//   top: 50%;
//   transform: translateY(-50%);
//   margin-right: 0;
//   opacity: 0;
// }

.effect.effectBtn {
  transition: all 0.2s linear 0s;
  text-transform: capitalize;

  img {
    opacity: 0;
    position: absolute;
    top: 8.5px;
    left: 12px;
  }
  &:before {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0px;
    height: 100%;
    // width: 0px;
    background-color: black;
    border-radius: 0 50% 50% 0;
    transform: scale(0, 1);
    transform-origin: left center;
    transition: all 0.2s linear 0s;
  }

  &:hover {
    text-indent: 28px;
    img {
      opacity: 1;
      transition: all 0.2s linear 0s;
      position: absolute;
      top: 8.5px;
      left: 10px;
    }
    &:before {
      transform: scale(1, 1);
      text-indent: 0;
    }
  }
}

.effect {
  text-align: center;
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: white;
  text-transform: capitalize;
  background-color: black;
  height: 40px;
  text-transform: capitalize;
  font-size: 0.875rem;
  padding: 6px 0px;
  width: 148px;
  height: 38px;
  font-weight: 600;
  border-radius: 32.5px;
  overflow: hidden;
}

.heartWrapper {
  margin-left: 1rem;

  padding: 1.4px;
  padding-bottom: 6px;
  background: linear-gradient(
    122deg,
    rgba(220, 134, 160, 1) 0%,
    rgba(160, 192, 233, 1) 25%,
    rgba(234, 165, 57, 1) 50%,
    rgba(132, 140, 201, 1) 75%,
    rgba(99, 209, 105, 1) 100%
  );
  cursor: pointer;
  border-radius: 30.5px;
  height: 42.3px;
  text-transform: capitalize;
  padding-top: 2.15px;
  padding-left: 2px;
  position: relative;
  span {
    position: relative;
    top: 2.2px;
  }
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.uai {
 
  top: -23px;
    right: 36px;

}
@media screen and (max-width: 375px) {
  .uai {
    top: -33px;
  }
}
@media screen and (max-width: 360px) {
  .uai {
    top: -50px;
  }
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.langDropDown {
  border-radius: 16px;
  box-shadow: none;
  border: 1.3px solid #d1d0d0;
  margin-top: 4px;
  ul {
    padding: 0;
    li:first-child {
      padding-top: 10px;
    }
    li:last-child {
      padding-bottom: 10px;
    }
  }
}

input[type='search'] {
  appearance: none !important;
}
#OffAmazonPaymentsWidgets1 {
  display: none;
}

.bannerHeroVideo {
  video::-webkit-media-controls {
    display: none !important;
  }
}

#AmazonPayButton {
  max-width: 100% !important;
  width: 100% !important;
  height: 78px !important;
  margin-left: 4.6rem;

  @media (max-width: 1050px) {
    margin-left: 3rem;
    height: 50px !important;
  }
  @media (max-width: 980px) {
    margin-left: 2rem;
  }
  @media (max-width: 500px) {
    margin-left: 0rem;
  }
}
